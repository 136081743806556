import React from 'react';
import { Text, Spacing, MessageBox, Icon } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * Renders a container for policy-related content with optional alert styling
 * This optional alert is shown in the checkout and complete pages for tenants with the feature SHOW_POLICIES_CONTAINER_ALERT enabled
 * @param {object} props - Component props
 * @param {React.ReactNode} props.children - Child elements
 * @param {string} props.location - Location identifier ('checkout' or other)
 * @param {string} props.customTitle - Title for the container (optional)
 * @returns {JSX.Element} Rendered component
 */
const PoliciesContainer = ({ children, location, customTitle }) => {
  const features = useWhitelabelFeatures();
  const { t } = useTranslation('seats');

  if (!features.SHOW_POLICIES_CONTAINER_ALERT) {
    return null;
  }

  const defaultTitle =
    location === 'checkout'
      ? t('passenger_policy.finalize_purchase')
      : t('passenger_policy.by_continuing');

  const displayTitle = customTitle || defaultTitle;

  return (
    <MessageBox borderColor="warning" borderRadius="L" roundCorners widthFull>
      <Spacing size="XS" alignItems="center">
        <Icon name="emoji Raised Hand" size="S" type="emojiRaisedHand" />
        <Text size="S" weight="semibold">
          {displayTitle}
        </Text>
      </Spacing>
      {children}
    </MessageBox>
  );
};

PoliciesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string,
  customTitle: PropTypes.string,
};

PoliciesContainer.defaultProps = {
  location: '',
  customTitle: '',
};

export default PoliciesContainer;
