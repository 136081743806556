import {
  trackReservationEvent,
  trackPurchaseEvent,
  gaTracker,
  ecTracker,
  trackEvent,
  serializePurchase as serializePurchaseForTracking,
} from 'user-analytics';
import { CurrentLang } from 'utils/lang';
import { getInstance } from 'utils/taplytics';
import store from '@/store';
import { removeURLParam } from 'utils/queryString/urlsCleaner';
import serializeReservation from './reservation';

function serializeEcommerceReservation(reservation) {
  const { transportType, departure, origin, destination, fragments, tripSlug } = reservation;
  let transporterName = [];
  let typeName;
  let hasSeatSelection;
  let commissionAmount = 0;

  if (transportType === 'bus') {
    const { line } = fragments[0];

    transporterName.push(line.name);
    typeName = 'bus';
    hasSeatSelection = line.allowsSeatSelection;
    commissionAmount = (line.commissionEstimate / 100) * reservation.pricing.total;
  } else if (transportType === 'flight') {
    transporterName.push(fragments[0].legs.map((leg) => leg.carrier.name));
    typeName = 'flight';
    hasSeatSelection = false;
    commissionAmount = (fragments[0].carrier.commissionEstimate / 100) * reservation.pricing.total;
  } else {
    typeName = 'multicarrier';
    hasSeatSelection = false;

    fragments.forEach((fragment) => {
      if (fragment.transportType === 'flight') {
        const { carrier } = fragment;

        transporterName.push(...fragment.legs.map((leg) => leg.carrier.name));
        commissionAmount += (carrier.commissionEstimate / 100) * reservation.pricing.total;
      } else {
        const { line } = fragment;

        transporterName.push(line.name);
        commissionAmount += (line.commissionEstimate / 100) * reservation.pricing.total;
      }
    });
  }

  transporterName = transporterName.join(':');

  return {
    transporterName,
    allowsSeatSelection: hasSeatSelection,
    tripSlug,
    transportType: typeName,
    total: reservation.pricing.total,
    commission: commissionAmount,
    departure,
    originId: origin.id,
    originName: origin.name,
    originCityId: origin.cityId,
    originCityName: origin.cityName,
    originCountryName: origin.countryName,
    destinationId: destination.id,
    destinationName: destination.name,
    destinationCityId: destination.cityId,
    destinationCityName: destination.cityName,
    destinationCountryName: destination.countryName,
  };
}

function serializeWalletUsed(walletType, walletBalanceUsed) {
  if (!walletType || !walletBalanceUsed) return undefined;

  return walletType;
}

/**
 * Serializes the payment details for a purchase.
 *
 * @param {string} paymentType - The type of payment used.
 * @param {string} paymentEngine - The engine handling the payment.
 * @returns {string} The display name of the payment type based on the engine.
 */
function serializePaymentType(paymentType, paymentEngine) {
  const paymentDisplays = {
    conekta: 'oxxo',
    paycash: 'paycash',
    kushki: 'kushki',
  };

  return paymentType === 'store' ? paymentDisplays[paymentEngine] : paymentType;
}

function serializeEcommercePurchase(data) {
  const {
    couponCode,
    departs,
    email,
    insuranceAmount,
    markup,
    passengerSelection,
    phone,
    phoneCode,
    returns,
    redirect = false,
    redirectedFrom = '',
    roundTrip,
    taxes,
    token,
    total,
    walletType,
    walletBalanceUsed,
    paymentType,
    paymentEngine,
    isExchange,
    passengersQuantity,
  } = data;
  return {
    email,
    departs: serializeEcommerceReservation(departs),
    discountCode: couponCode,
    insuranceAmount,
    markup,
    passengerSelection,
    phone: `${phoneCode}${phone}`,
    redirect,
    redirectedFrom,
    returns: roundTrip ? serializeEcommerceReservation(returns) : null,
    taxes,
    token,
    total,
    walletUsed: serializeWalletUsed(walletType, walletBalanceUsed),
    walletAmount: walletBalanceUsed,
    paymentType: serializePaymentType(paymentType, paymentEngine),
    isExchange,
    passengersQuantity,
  };
}

function returnSeatSelectionStep(departs) {
  const trip = departs.fragments[0];
  const departureSeatSelection = trip.transportType === 'bus' && trip.line.allowsSeatSelection;
  let step = 2;

  if (departureSeatSelection) {
    step += 1;
  }

  return step;
}

/**
 * Function to check if the purchase has adjacent seats
 * @param {Object} departs
 * @param {Object} returns
 * @returns {boolean}
 */
function checkForAdjacentSeats(departs, returns) {
  const selectedSeatsDeparts = departs?.selectedSeats || [];
  const selectedSeatsReturns = returns?.selectedSeats || [];
  const selectedSeats = selectedSeatsDeparts.concat(selectedSeatsReturns);
  return selectedSeats.some((seat) => seat.isPickedAsAdjacent);
}

function serializePurchase(purchase) {
  const {
    token,
    total,
    taxes,
    markup,
    insuranceAmount,
    international,
    passengerSelection,
    departs,
    returns,
    openTicket,
    couponCode,
    redirect = false,
    redirectedFrom = '',
    recommendedTripType,
    useRecommendedTrip,
    preSelectedSeats,
    bulkTicket,
  } = purchase;

  return {
    token,
    pricing: {
      total,
      taxes,
      markup,
      insuranceAmount,
    },
    international,
    passengerSelection,
    departs: serializeReservation(departs),
    returns: serializeReservation(returns),
    openTicket,
    discountCode: couponCode,
    lang: CurrentLang({ shouldReadDefaultLanguageFromStore: false }),
    redirect,
    redirectedFrom,
    isUsingAdjacentSeats: checkForAdjacentSeats(departs, returns),
    recommendedTripType,
    useRecommendedTrip,
    resultSeats: Boolean(preSelectedSeats),
    bulkTicket: Boolean(bulkTicket),
  };
}

export function purchaseAttemptTracker(purchase) {
  const { env } = store.getState().whitelabelConfig;
  if (env.taplytics.enabled) {
    const taplytics = getInstance();
    taplytics.track('Purchase Attempt', serializePurchase(purchase));
  }
  const queryParams = new URLSearchParams(window?.location.search);
  if (queryParams.has('redirected_to')) {
    const analyticsData = {
      ...serializePurchaseForTracking(serializePurchase(purchase)),
      'Email Type': queryParams.get('redirected_to'),
    };
    queryParams.delete('redirected_to');
    window?.history.replaceState({}, null, removeURLParam('redirected_to', window?.location.href));
    trackEvent('Email Link Clicked', analyticsData);
  }
  trackPurchaseEvent('Purchase Attempt', serializePurchase(purchase));
  ecTracker.trackAddToCart(serializeEcommercePurchase(purchase));
}

export function exchangeAttemptTracker(purchase) {
  trackPurchaseEvent('Exchange Attempt', serializePurchase(purchase));
  ecTracker.trackAddToCart(serializeEcommercePurchase(purchase));
}

export function passengersTracker(purchase) {
  trackPurchaseEvent('Passengers Created', serializePurchase(purchase));
  ecTracker.trackPurchaseFunnel(1, serializeEcommercePurchase(purchase));
}

export function exchangePassengersTracker(purchase) {
  trackPurchaseEvent('Exchange Passengers Created', serializePurchase(purchase));
  ecTracker.trackPurchaseFunnel(1, serializeEcommercePurchase(purchase));
}

export function seatChangeTracker(purchase = {}, way, fromView) {
  if (fromView === 'results') {
    trackEvent('Seat Change', {
      Way: way,
      View: fromView,
    });
    return;
  }

  const serializedPurchase = serializeEcommercePurchase(purchase);
  const { departs, returns } = purchase;

  if (way === 'departure') {
    // segment
    trackReservationEvent('Seat Change', serializeReservation(departs, 'departs'));
    // GA
    if (departs.transportType === 'bus' && departs.fragments[0].line.allowsSeatSelection) {
      ecTracker.trackPurchaseFunnel(2, serializedPurchase);
    }
  } else {
    // segment
    trackReservationEvent('Seat Change', serializeReservation(returns, 'returns'));
    // GA
    if (returns.transportType === 'bus' && returns.fragments[0].line.allowsSeatSelection) {
      ecTracker.trackPurchaseFunnel(returnSeatSelectionStep(departs), serializedPurchase);
    }
  }
}

export function exchangeSeatChangeTracker(purchase, way) {
  const serializedPurchase = serializeEcommercePurchase(purchase);
  const { departs, returns } = purchase;

  if (way === 'departure') {
    // segment
    trackReservationEvent('Exchange Seat Change', serializeReservation(departs, 'departs'));
    // GA
    if (departs.transportType === 'bus' && departs.fragments[0].line.allowsSeatSelection) {
      ecTracker.trackPurchaseFunnel(2, serializedPurchase);
    }
  } else {
    // segment
    trackReservationEvent('Exchange Seat Change', serializeReservation(returns, 'returns'));
    // GA
    if (returns.transportType === 'bus' && returns.fragments[0].line.allowsSeatSelection) {
      ecTracker.trackPurchaseFunnel(returnSeatSelectionStep(departs), serializedPurchase);
    }
  }
}

export function confirmItineraryTracker(purchase) {
  trackPurchaseEvent('Confirm Itinerary', serializePurchase(purchase));
}

export function exchangeConfirmItineraryTracker(purchase) {
  trackPurchaseEvent('Exchange Confirm Itinerary', serializePurchase(purchase));
}

export function purchaseOnHoldTracker(token, callback) {
  gaTracker.trackPurchaseOnHold(token, callback);
}

/**
 * Tracks the purchase and the purchase funnel step with additional details.
 * @param {Object} params - The parameters to be tracked.
 * @param {Object} params.purchase - The purchase object to be tracked.
 * @param {number} params.funnelStep - The step number in the purchase funnel.
 * @param {string} params.cardBrand - The brand of the card used for the purchase.
 * @param {string} params.paymentType - The type of payment used for the purchase.
 * @param {string} params.paymentEngine - The engine used for the payment.
 * @param {boolean} params.isExchange - Whether the purchase is an exchange.
 * @param {Function} callback - Callback function to execute after tracking.
 */
export function purchaseTracker(
  { purchase, funnelStep, cardBrand, paymentType, paymentEngine, isExchange, passengersQuantity },
  callback,
) {
  const serializedPurchase = serializeEcommercePurchase({
    ...purchase,
    paymentType,
    paymentEngine,
    isExchange,
    passengersQuantity,
  });

  ecTracker.trackPurchase(serializedPurchase);
  ecTracker.trackPurchaseFunnel(funnelStep, serializedPurchase, cardBrand, callback);
}

/**
 * Event to track when the user click on the return CTA offer reminder
 * The return CTA is present in passengers and checkout pages if ft flag is enabled
 */
export function returnCTATracker(buttonVariant) {
  trackEvent('Return Reminder Clicked', {
    Button: buttonVariant,
  });
}
