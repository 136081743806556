import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { When } from 'react-if';
import { connect } from 'react-redux';
import { isWomanSeat, isPetFriendlySeat } from 'utils/purchase/seats';
import { Box, Checkbox, Spacing, Text, Toggle } from '@reservamos/elements';
import Loading from 'components/Loading';
import CheckoutNavigation from 'components/purchase/CheckoutNavigation';
import SeatsLegend from 'components/SeatsLegend';
import BusDiagram from 'components/purchase/BusDiagram';
import { withTranslation } from 'react-i18next';
import MobileBottomContainer from 'ui/atoms/MobileBottomContainer';
import { trackEvent } from 'user-analytics';
import { CONTINUE_WITH_SEATS } from 'constants/TrackEvents';
import { getSeatsCount, isSeatsOnResultActivated } from 'utils/seats';
import NewSeatsLegend from 'components/NewSeatsLegend';
import { trackViewSeats } from 'metrics/user-analytics/search';
import NewSeatsLayout from 'ui/templates/NewSeatsLayout';
import MobileDetailResult from 'ui/atoms/MobileDetailResult';
import { isDesktopSize } from 'utils/Reserbus';
import ModalWithTheme from 'components/ModalWithTheme';
import SeatsPreviewPricing from 'components/seats/SeatsPreviewPricing';
import BusDiagramNotFoundError from 'ui/molecules/BusDiagramNotFoundError';
import DotersSessionAlert from 'ui/atoms/DotersSessionAlert';
import PetBadge from 'images/badges/pet.svg';
import { CurrentLang } from 'utils/lang';
import PetsDialog from '../../ui/atoms/PetsDialog';
import InfoText from '../../ui/atoms/InfoText';
import HeaderDiagramBus from '../HeaderDiagramBus';
import MainButton from '../../ui/atoms/MainButton';
import NotAllowedSeats from './NotAllowedSeats';
import CounterSeat from '../../ui/atoms/CounterSeat';
import SelectedSeats from '../SelectedSeats';
import AdjacentSeat from '../../ui/atoms/AdjacentSeat';
import calculateAdjacentCardVisibility from '../../utils/adjacentCard';

const propTypes = {
  diagramType: PropTypes.string,
  way: PropTypes.string.isRequired,
  layout: PropTypes.array,
  seats: PropTypes.object,
  passengers: PropTypes.array,
  selectedSeats: PropTypes.array.isRequired,
  lockedSeats: PropTypes.array.isRequired,
  canGoBack: PropTypes.bool,
  selectSeats: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  onBackAction: PropTypes.func,
  onFinishedSelection: PropTypes.func.isRequired,
  allowsSeatSelection: PropTypes.bool.isRequired,
  isExchange: PropTypes.bool,
  isOpenTicket: PropTypes.bool,
  t: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
  busCategories: PropTypes.array,
  minPassengers: PropTypes.number,
  updateSeat: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isPetFriendly: PropTypes.bool,
  tripPricing: PropTypes.object,
  tripId: PropTypes.string,
  firstFloorReclination: PropTypes.string,
  secondFloorReclination: PropTypes.string,
  resultsLayout: PropTypes.bool,
  onClose: PropTypes.func,
  hasSeats: PropTypes.bool,
  trip: PropTypes.object,
  clearError: PropTypes.func,
  fetchingDetailsError: PropTypes.bool,
  copies: PropTypes.object.isRequired,
  passengerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }),
  ),
  isMultiTrip: PropTypes.bool,
  firstTripIsActive: PropTypes.bool,
  secondTripIsActive: PropTypes.bool,
  routeLabel: PropTypes.string,
  headerLegend: PropTypes.string,
  roundTrip: PropTypes.bool,
};

const defaultProps = {
  isExchange: false,
  canGoBack: false,
  diagramType: 'bus',
  passengers: [],
  seats: {},
  minPassengers: 0,
  isMultiTrip: false,
  firstTripIsActive: false,
  secondTripIsActive: false,
  routeLabel: '',
  headerLegend: '',
  roundTrip: false,
};

/**
 * This component is used to render the seat selection page
 */
class SeatSelector extends Component {
  constructor(props) {
    super(props);

    const { features } = this.props;

    const defaultDeck = features.DEFAULT_DECK === 'first' ? 0 : 1;
    this.changeSeat = this.changeSeat.bind(this);
    this.finishSeatSelection = this.finishSeatSelection.bind(this);
    this.acceptWomanPolicies = this.acceptWomanPolicies.bind(this);
    this.handlePetDialogPress = this.handlePetDialogPress.bind(this);
    this.handlePetDialogShowPress = this.handlePetDialogShowPress.bind(this);
    this.seatProps = this.seatProps.bind(this);
    this.state = {
      womanPoliciesAccepted: false,
      hidePetDialog: true,
      showAdjacentCard: false,
      toggleAdjacentCard: false,
      errors: {
        womanPoliciesAccepted: false,
      },
      selectedDeck: defaultDeck,
    };
    this.deselectIfExceed = this.deselectIfExceed.bind(this);
    this.controlAdjacentCard = this.controlAdjacentCard.bind(this);
    this.handleAdjacentToggle = this.handleAdjacentToggle.bind(this);
    this.turnOffAdjacentToggle = this.turnOffAdjacentToggle.bind(this);
    this.isAdjacentPicked = this.isAdjacentPicked.bind(this);
    this.updateAdjacentSeats = this.updateAdjacentSeats.bind(this);
    this.removeAdjacentSeats = this.removeAdjacentSeats.bind(this);
    this.isAdjacentSelected = this.isAdjacentSelected.bind(this);
    this.getSeatFromBusDiagram = this.getSeatFromBusDiagram.bind(this);
    this.womanSeatsSelected = this.womanSeatsSelected.bind(this);
    this.switchDeck = this.switchDeck.bind(this);
    this.toggleSwitchDeck = this.toggleSwitchDeck.bind(this);
  }

  componentDidMount() {
    const storagePetDialogHidden = localStorage.getItem('hidePetDialog');
    this.setState({ hidePetDialog: storagePetDialogHidden });
    this.deselectIfExceed();
  }

  componentDidUpdate(_, prevState) {
    const { showAdjacentCard, toggleAdjacentCard } = this.state;
    const { trip, way, hasSeats, isMultiTrip } = this.props;

    if (!isMultiTrip && trip && hasSeats && !this.viewSeatsSent) {
      this.viewSeatsSent = true;
      trackViewSeats(trip, way);
    }
    if (prevState.showAdjacentCard !== showAdjacentCard) {
      if (!showAdjacentCard && toggleAdjacentCard) {
        this.removeAdjacentSeats();
      }
    }

    if (prevState.toggleAdjacentCard !== toggleAdjacentCard) {
      if (!toggleAdjacentCard) {
        this.removeAdjacentSeats();
      }
    }
  }

  handlePetDialogPress() {
    const storagePetDialogHidden = localStorage.getItem('hidePetDialog');
    if (!storagePetDialogHidden) {
      localStorage.setItem('hidePetDialog', true);
    }
    this.setState({ hidePetDialog: true });
  }

  handlePetDialogShowPress() {
    this.setState({ hidePetDialog: false });
  }

  handleAdjacentToggle(e) {
    const isChecked = e.target.checked;
    this.updateAdjacentSeats(isChecked);
    this.setState({ toggleAdjacentCard: isChecked });
  }

  getSeatFromBusDiagram(seatNumber, seatLevel) {
    const { seats } = this.props;
    // ? If seat level exists, recreate diagram taking that value into account
    const seatFromDiagram = seatLevel
      ? Object.values(seats).find((seat) => seat.level === seatLevel && seat.number === seatNumber)
      : seats[seatNumber];
    if (!seatFromDiagram) return {};

    return seatFromDiagram;
  }

  switchDeck({ selectedDeck }) {
    this.setState({
      selectedDeck,
    });
  }

  toggleSwitchDeck() {
    const { selectedDeck } = this.state;
    this.switchDeck({ selectedDeck: selectedDeck === 0 ? 1 : 0 });
  }

  removeAdjacentSeats() {
    this.deselectIfExceed(true);
  }

  updateAdjacentSeats(isChecked) {
    const { selectedSeats, seats, updateSeat } = this.props;

    if (!isChecked) {
      this.removeAdjacentSeats();
      return;
    }

    /** Only 1 selected seat can be adjacent, that's why filter for the unique with adjacent is valid */
    // TODO: Select the parent which has the adjacent seat selected otherwise select parent seat available
    const parentSeat = selectedSeats.find((seat) => seat.adjacentSeats);
    if (!parentSeat) return;

    /**
     * Get the adjacent seat from the selected parent seat
     * Set the adjacent seat from seats object, this because seats object has all the properties we need
     */
    const adjacentSeatNumber = parentSeat.adjacentSeats.numbers[0];
    let adjacentSeat = Object.values(seats).find((seat) => seat.number === adjacentSeatNumber);

    /** Verify if the adjacent seat is already selected or we must select it first */
    const isAdjacentPicked = this.isAdjacentPicked(parentSeat.number);

    adjacentSeat = {
      ...adjacentSeat,
      isPickedAsAdjacent: !isAdjacentPicked,
    };

    const isAdjacentCurrentlySelected = this.isAdjacentSelected(adjacentSeat.number);

    if (isAdjacentCurrentlySelected) {
      updateSeat({ ...parentSeat, isAdjacentPicked: true });
      updateSeat(adjacentSeat);
    } else {
      this.changeSeat(adjacentSeat);
      updateSeat({ ...parentSeat, isAdjacentPicked: true });
    }
  }

  /**
   * Returns true if the adjacent seat is already picked
   * @param {Number} adjacentSeatNumber
   * @returns Bool
   */
  isAdjacentPicked(adjacentSeatNumber) {
    const { selectedSeats } = this.props;
    const isAlreadyPicked = selectedSeats.some(
      (seat) => seat.number === adjacentSeatNumber && seat.isPickedAsAdjacent,
    );
    return isAlreadyPicked;
  }

  /**
   * This function verifies if the adjacent seat is already selected
   * @param {Number} adjacentSeatNumber - The adjacent seat number
   * @returns {Bool} - True if the adjacent seat is already selected
   */
  isAdjacentSelected(adjacentSeatNumber) {
    const { selectedSeats } = this.props;
    const isAlreadySelected = selectedSeats.some((seat) => seat.number === adjacentSeatNumber);
    return isAlreadySelected;
  }

  /**
   * Turn off toggle
   * @param {Array} seats
   */
  turnOffAdjacentToggle(seats) {
    const { toggleAdjacentCard, isAdjacentCardVisible } = this.state;
    if (seats.length < 2 && isAdjacentCardVisible && toggleAdjacentCard) {
      this.setState({ toggleAdjacentCard: false });
    }
  }

  /**
   * This function will deselect the seats until we have the same number of seats as passengers
   * This happens when we have changed departure seats and in the return trip we selected more seats than passengers
   * @param {Bool} removeAdjacent - If true, will remove the adjacent seats
   */
  deselectIfExceed(removeAdjacent = false) {
    const { passengers, selectedSeats, selectSeats, isMultiTrip } = this.props;

    /**
     * For trips with connections, this case is handled by MultiTripSeatSelector.
     * So we don't need to execute this logic
     */
    if (isMultiTrip) return;

    let newSeats = selectedSeats.slice(0);
    /*
     * Avoids overwriting the state of tickets when there are no seats selected yet
     */
    if (!newSeats.length) {
      return;
    }
    if (newSeats.length >= passengers.length) {
      // TODO: This is a temporary fix, we need to find a better way to handle this
      const passengersCount =
        passengers.length - newSeats.filter((seat) => !seat.isPickedAsAdjacent).length;
      newSeats = newSeats.slice(passengersCount * -1);
    }
    newSeats = newSeats.map((seat) => {
      const seatFromDiagram = this.getSeatFromBusDiagram(seat.number, seat?.seatLevel);
      return {
        ...seat,
        ...seatFromDiagram,
        ...(seatFromDiagram.adjacentSeats && {
          adjacentSeats: { ...seat.adjacentSeats, ...seatFromDiagram.adjacentSeats },
        }),
        ...(removeAdjacent && { isPickedAsAdjacent: false, isAdjacentPicked: false }),
      };
    });
    this.controlAdjacentCard(newSeats);
    selectSeats(newSeats);
  }

  controlAdjacentCard(seatsSelected) {
    const { isAdjacentCardVisible, shouldTurnOnToggleValue } =
      calculateAdjacentCardVisibility(seatsSelected);

    const showAdjacentCard = isAdjacentCardVisible;
    this.setState({ showAdjacentCard, toggleAdjacentCard: shouldTurnOnToggleValue });
  }

  changeSeat(seat) {
    const {
      selectedSeats,
      selectSeats,
      isExchange,
      features,
      minPassengers,
      clearError,
      roundTrip,
    } = this.props;
    const { SEAT_SELECTION_LIMIT, ROUND_SEAT_SELECTION_LIMIT } = features;
    const { number: seatNumber, seatLevel } = seat;
    const action = selectedSeats.some(
      ({ number, seatLevel: selectedSeatLevel }) =>
        number === seatNumber && (!seatLevel || seatLevel === selectedSeatLevel),
    )
      ? 'REMOVE'
      : 'ADD';

    if (isExchange && action === 'ADD') {
      if (selectedSeats.length === minPassengers) selectedSeats.length = 0;

      selectedSeats.push(seat);
      selectSeats(selectedSeats);
      return;
    }

    let newSeats = selectedSeats.slice(0);

    if (action === 'REMOVE') {
      newSeats = newSeats.filter(
        ({ number, seatLevel: selectedSeatLevel }) =>
          number !== seatNumber || (seatLevel && seatLevel !== selectedSeatLevel),
      );
    } else {
      const adjacentSeatsCount = newSeats.filter((seat) => seat.isPickedAsAdjacent).length;
      let seatsCount = newSeats.length - adjacentSeatsCount;

      // Cuando seleccionamos un asiento que puede ser adyacente a otro,
      // si tenemos un adyacente marcado, sumamos 1 al contador de asientos
      // porque lo vamos a desactivar con controlAdjacentCard()
      if (seat.adjacentSeats) {
        // También verificamos que la cantidad de pasajeros no sea ya igual a la cantidad de asientos
        if (adjacentSeatsCount && !(seatsCount === minPassengers)) {
          seatsCount += 1;
        }
      }

      // TODO: We need to find a better way to handle this shifts
      if (seatsCount === minPassengers && !seat.isPickedAsAdjacent) {
        newSeats.shift();

        if (newSeats.some((seat) => seat.isPickedAsAdjacent || seat.isAdjacentPicked)) {
          newSeats.shift();
        }
      }

      if (clearError) clearError();

      newSeats.push(seat);
    }

    this.controlAdjacentCard(newSeats);

    let seatLimit = SEAT_SELECTION_LIMIT;
    /**
     * If it is a round trip and we have set a limit specific for round trips,
     * we use that limit instead of the default one.
     * This is useful when we have a limit of 5 seats for a round trip and 10 seats for a one way trip.
     */
    if (roundTrip && ROUND_SEAT_SELECTION_LIMIT) {
      seatLimit = ROUND_SEAT_SELECTION_LIMIT;
    }
    if (newSeats.length > seatLimit) return;

    selectSeats(newSeats);
  }

  womanSeatsSelected() {
    const { hasSeats, features, isOpenTicket, seats, selectedSeats } = this.props;
    return (
      hasSeats &&
      Boolean(
        features.WOMAN_SEATS_ENABLED &&
          !isOpenTicket &&
          selectedSeats.some(({ number }) => isWomanSeat(seats[number].category)),
      )
    );
  }

  finishSeatSelection() {
    const {
      selectedSeats,
      sendNotification,
      onFinishedSelection,
      allowsSeatSelection,
      minPassengers,
      way,
    } = this.props;
    const { womanPoliciesAccepted } = this.state;
    const womanSeatSelected = this.womanSeatsSelected();

    if (!womanPoliciesAccepted && womanSeatSelected) {
      this.setState({ errors: { womanPoliciesError: true } });
      return;
    }

    const selectedSeatCount = selectedSeats.filter((seat) => !seat.isPickedAsAdjacent).length;

    trackEvent(CONTINUE_WITH_SEATS, { way });

    if (!allowsSeatSelection) {
      return onFinishedSelection();
    }

    if (selectedSeatCount < 1 || selectedSeatCount < minPassengers) {
      sendNotification('you_need_to_choose_some_seats', { count: minPassengers || 1 });
    } else {
      window.scrollTo(0, 0);
      onFinishedSelection();
    }
  }

  acceptWomanPolicies() {
    const { womanPoliciesAccepted, errors } = this.state;
    this.setState({
      womanPoliciesAccepted: !womanPoliciesAccepted,
      errors: {
        ...errors,
        womanPoliciesError: !!womanPoliciesAccepted,
      },
    });
  }

  seatProps(seatNumber) {
    const { seats } = this.props;
    return isWomanSeat(seats[seatNumber].category)
      ? { className: 'selected-seats-boxes__woman' }
      : {};
  }

  render() {
    const {
      way,
      layout,
      selectedSeats,
      lockedSeats,
      canGoBack,
      onBackAction,
      diagramType,
      allowsSeatSelection,
      isOpenTicket,
      t,
      seats,
      features,
      busCategories,
      minPassengers,
      isExchange,
      isLoading,
      isPetFriendly,
      tripPricing,
      tripId,
      resultsLayout,
      onClose,
      hasSeats,
      trip,
      fetchingDetailsError,
      copies,
      isMultiTrip,
      firstTripIsActive,
      secondTripIsActive,
      routeLabel,
      headerLegend,
      passengerTypes,
      firstFloorReclination,
      secondFloorReclination,
      roundTrip,
    } = this.props;

    const {
      womanPoliciesAccepted,
      hidePetDialog,
      showAdjacentCard,
      toggleAdjacentCard,
      selectedDeck,
    } = this.state;

    let buttonContext = t('seats:select_seats', { count: selectedSeats.length });
    const selectedPassengerSeats = selectedSeats.filter((seat) => !seat.isPickedAsAdjacent).length;
    if (selectedPassengerSeats < minPassengers) {
      buttonContext = t('seats:seat_quantity', { count: minPassengers - selectedPassengerSeats });
    }

    if (!allowsSeatSelection) {
      buttonContext = t('seats:next');
    } else if (selectedSeats.length === 0) {
      buttonContext = minPassengers
        ? t('seats:seat_quantity', { count: minPassengers - selectedSeats.length })
        : t('seats:select_at_least_one_seat');
    }

    const busHasPetFriendlySeats =
      hasSeats &&
      Object.keys(seats).some((key) => {
        return isPetFriendlySeat(seats[key].category);
      });
    const tripIsPetFriendly = isPetFriendly;
    const showPetFriendly = Boolean(busHasPetFriendlySeats && tripIsPetFriendly);

    const womanSeatSelected = this.womanSeatsSelected();

    const showWomanLegends = Boolean(
      hasSeats &&
        Boolean(
          features.WOMAN_SEATS_ENABLED &&
            !isOpenTicket &&
            Boolean(seats.categories?.find((category) => isWomanSeat(category))),
        ),
    );

    const petFriendlyAvailability =
      hasSeats &&
      Boolean(
        Object.keys(seats).some(
          (key) => isPetFriendlySeat(seats[key].category) && !seats[key].occupied,
        ),
      ) &&
      Boolean(busCategories?.some(({ type }) => isPetFriendlySeat(type)));

    const petFriendlySoldOut =
      isPetFriendly && !petFriendlyAvailability && features.SOLDOUT_PET_MESSAGE_ENABLED;
    // TODO Verify if this is the correct way to check if the trip is pet friendly when backend is ready
    const petFriendlyOnTheTrip =
      hasSeats &&
      petFriendlyAvailability &&
      Boolean(
        Object.keys(seats).some(
          (key) => seats[key].occupied && seats[key].category === 'pet_friendly',
        ),
      );

    const isSelectedPetFriendly = selectedSeats.some(({ category }, _, array) => {
      return showPetFriendly && isPetFriendlySeat(category) && array.length > 1;
    });

    const isMainButtonDisabled =
      (features.WOMAN_SEATS_ENABLED && womanSeatSelected && !womanPoliciesAccepted) || isLoading;

    const { errors } = this.state;
    const { womanPoliciesError } = errors;
    const isFlat = features.FUNNEL_STYLE === 'FLAT';
    const isMobile = !isDesktopSize();

    const isSeatsOnResults = isSeatsOnResultActivated();

    const busDiagramComponent = hasSeats ? (
      <BusDiagram
        id={tripId}
        layout={layout}
        diagramType={diagramType}
        disabled={!allowsSeatSelection}
        selectedSeats={selectedSeats}
        lockedSeats={lockedSeats}
        changeSeat={this.changeSeat}
        seats={selectedSeats}
        seatProps={this.seatProps}
        showPetFriendly={isPetFriendly}
        petFriendlySoldOut={petFriendlySoldOut}
        isExchange={isExchange}
        isResultsLayout={resultsLayout}
        selectedDeck={selectedDeck}
        switchDeck={this.switchDeck}
        key={tripId}
        verticalSeats={features.VERTICAL_SEATS_LAYOUT}
        firstFloorReclination={firstFloorReclination}
        secondFloorReclination={secondFloorReclination}
        showUniformLayout={features.SHOW_UNIFORM_LAYOUT}
      />
    ) : (
      <Loading loadingText={t('seats:searching_available_seats')} />
    );

    const hasSecondDeck = layout?.[1];
    const seatsCount = getSeatsCount({ seats, selectedSeats });

    const seatsLegend = isSeatsOnResults ? (
      <NewSeatsLegend
        layout={layout}
        showPetFriendly={showPetFriendly}
        showWomanLegends={showWomanLegends}
        isExchange={isExchange}
        occupied={seatsCount.occupied}
        available={seatsCount.available}
        selected={seatsCount.selected}
        isHorizontal={!resultsLayout || isFlat}
        isBoxMobile={isFlat}
      />
    ) : (
      <SeatsLegend
        layout={layout}
        showPetFriendly={showPetFriendly}
        showWomanLegends={showWomanLegends}
        isExchange={isExchange}
        occupied={seatsCount.occupied}
        available={seatsCount.available}
        selected={seatsCount.selected}
      />
    );

    const pricingComponent = (
      <SeatsPreviewPricing
        id={tripId}
        tripPricing={tripPricing}
        passengerTypes={passengerTypes}
        seatsSelected={selectedSeats}
        ctaText={buttonContext}
        showWomanSwitch={womanSeatSelected}
        onWomanSwitchChange={this.acceptWomanPolicies}
        womanSwitchValue={womanPoliciesAccepted}
        womanPoliciesError={womanPoliciesError}
        showPetFriendly={isPetFriendly}
        onContinue={this.finishSeatSelection}
        onSelectAdjacent={this.handleAdjacentToggle}
        showAdjacentCard={showAdjacentCard}
        adjacentSwitchValue={toggleAdjacentCard}
      />
    );

    if (isLoading && !resultsLayout) return <Loading loadingText={t('seats:loading')} />;

    const hasSelectedSeats = Boolean(selectedSeats.length);
    const modalTitle =
      way === 'departure'
        ? t('purchase:title.choose_your_departing_seats')
        : t('purchase:title.choose_your_return_seats');

    const notMultipleDecksComponent = isFlat ? (
      <Text weight="bold" mobileSize="M">
        {i18n.t('seats:single_deck_bus')}
      </Text>
    ) : null;

    const lang = CurrentLang({ shouldReadDefaultLanguageFromStore: true });
    const { seatsDisclaimer } = copies.policies[lang] || {};

    const toggleHeader = (fullWidth) => (
      <Spacing size="XXS" mobileAlign="center" vertical>
        {hasSecondDeck ? (
          <Toggle
            isChecked={selectedDeck === 1}
            onClick={this.toggleSwitchDeck}
            isMobile={isMobile}
            isFlat={isFlat}
            firstLabel={i18n.t('seats:1st_floor')}
            secondLabel={i18n.t('seats:2nd_floor')}
            firstIcon={showPetFriendly && PetBadge}
            fullWidth={fullWidth}
            id={`deck-toggle-${tripId}`}
          />
        ) : (
          notMultipleDecksComponent
        )}

        {Boolean(seatsDisclaimer) && <Text size="XS">{seatsDisclaimer}</Text>}
      </Spacing>
    );

    const isFlatStyle = features.FUNNEL_STYLE === 'FLAT';
    return (
      <>
        <When condition={resultsLayout}>
          <When condition={isMobile}>
            <ModalWithTheme
              footer={!fetchingDetailsError && pricingComponent}
              removeContentPadding
              title={!fetchingDetailsError && modalTitle}
              hasBlushing
              expandedModal
              onCloseModal={onClose}
            >
              {!fetchingDetailsError && (
                <NewSeatsLayout
                  pricing={<div className="seats-preview-pricing">{pricingComponent}</div>}
                  toggle={toggleHeader(true)}
                  seatsLegend={seatsLegend}
                  busDiagram={busDiagramComponent}
                  isLoading={isLoading}
                  mobileHeader={<MobileDetailResult trip={trip} />}
                  isFlat={isFlat}
                />
              )}
              {fetchingDetailsError && <BusDiagramNotFoundError isFlat={isFlatStyle} />}
            </ModalWithTheme>
          </When>
          <When condition={!isMobile}>
            {!fetchingDetailsError && (
              <NewSeatsLayout
                pricing={pricingComponent}
                toggle={toggleHeader(true)}
                seatsLegend={seatsLegend}
                busDiagram={busDiagramComponent}
                isLoading={isLoading}
                isFlat={isFlat}
              />
            )}

            {fetchingDetailsError && <BusDiagramNotFoundError isFlat={isFlatStyle} />}
          </When>
          {showPetFriendly && (
            <PetsDialog hideDialog={hidePetDialog} onPress={this.handlePetDialogPress} />
          )}
        </When>

        {/* Layout for purchase flow */}
        <When condition={!resultsLayout}>
          <div className="seats-container">
            <Spacing vertical size="M">
              <When condition={allowsSeatSelection}>
                <DotersSessionAlert />
                <div className="seats-summary">
                  <HeaderDiagramBus
                    handlePetDialogShowPress={this.handlePetDialogShowPress}
                    showPetFriendly={isPetFriendly}
                    legend={headerLegend}
                  />
                  {seatsLegend}
                </div>
                {isSeatsOnResults && toggleHeader()}
                {busDiagramComponent}
                <AdjacentSeat
                  isVisible={showAdjacentCard}
                  hasToggleSwitch
                  title={t('seats:adjacent_seat.title')}
                  text={t('seats:adjacent_seat.message')}
                  onChange={this.handleAdjacentToggle}
                  checked={toggleAdjacentCard}
                />
              </When>
              <When condition={!allowsSeatSelection}>
                <NotAllowedSeats way={way} t={t} isOpenTicket={isOpenTicket} />
              </When>

              {features.WOMAN_SEATS_ENABLED && womanSeatSelected && (
                <div className="seats-action-disclaimer">
                  <Checkbox
                    name="woman-disclaimer"
                    id="woman-disclaimer"
                    label={t('seats:confirm_iam_woman')}
                    checked={womanPoliciesAccepted}
                    onChange={this.acceptWomanPolicies}
                  />
                </div>
              )}

              <CheckoutNavigation
                leftContent={
                  <Spacing vertical size="S">
                    {hasSelectedSeats && (
                      <MobileBottomContainer>
                        <AdjacentSeat
                          isVisible={showAdjacentCard}
                          hasToggleSwitch
                          title={t('seats:adjacent_seat.title')}
                          onChange={this.handleAdjacentToggle}
                          checked={toggleAdjacentCard}
                          isMobile
                        />
                        <CounterSeat
                          roundTrip={roundTrip}
                          hasPetFriendly={isSelectedPetFriendly}
                          hasScale={isMultiTrip}
                          firstTripIsActive={firstTripIsActive}
                          secondTripIsActive={secondTripIsActive}
                          routeLabel={routeLabel}
                        >
                          {hasSeats && (
                            <SelectedSeats seats={selectedSeats} seatProps={this.seatProps} />
                          )}
                        </CounterSeat>
                      </MobileBottomContainer>
                    )}
                    {canGoBack && (
                      <p className="seats-action-link" onClick={onBackAction}>
                        {t('seats:change_departing_trip_seats')}
                      </p>
                    )}
                  </Spacing>
                }
              >
                {petFriendlyOnTheTrip && (
                  <Box
                    paddingHorizontal="XS"
                    paddingVertical="XS"
                    paddingHorizontalMobile="S"
                    paddingVerticalMobile="S"
                    borderRadius="S"
                    hasShadow
                  >
                    <InfoText>{t('seats:pet_dialog.disclaimer_pet_on_board')}</InfoText>
                  </Box>
                )}
                <MainButton
                  label={buttonContext}
                  onClick={this.finishSeatSelection}
                  isDisabled={isMainButtonDisabled}
                  isLoading={isLoading}
                />
                {showPetFriendly && (
                  <PetsDialog hideDialog={hidePetDialog} onPress={this.handlePetDialogPress} />
                )}
              </CheckoutNavigation>
            </Spacing>
          </div>
        </When>
      </>
    );
  }
}

SeatSelector.propTypes = propTypes;

SeatSelector.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  features: state.whitelabelConfig.features,
  copies: state.whitelabelConfig.copies,
});

export default connect(mapStateToProps)(withTranslation('')(SeatSelector));
